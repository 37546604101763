import axios from 'axios'

import { ENDPOINT } from '../../env'

export const fetch = async (path, id) => {
    let endpoint, mock, responsePath
    let useRaw = true

    switch (true) {
        case path.join('_') === 'stats_standings':
            endpoint = `client/standings/${id}`
            useRaw = false
            break
        case path.join('_') === 'stats_chess':
            endpoint = `client/standings/${id}?chess=true`
            useRaw = false
            break
        case path.join('_') === 'stats_scorers':
            endpoint = `client/rankings/${id}?scope=gs&limit=50`
            break
        case path.join('_') === 'stats_assists':
            endpoint = `client/rankings/${id}?scope=a&limit=50`
            break
        case path.join('_') === 'stats_mvp':
            endpoint = `client/rankings/${id}?scope=mvp&limit=50`
            break
        case path.join('_') === 'stats_cards':
            endpoint = `client/rankings/${id}?scope=cards&limit=50`
            break
        case path.join('_') === 'results_bydate':
            endpoint = `client/results/${id}?layout=bydate`
            break
        case path.join('_') === 'results_bytour':
            endpoint = `client/results/${id}?layout=bytour`
            break
        case path.join('_') === 'schedule_bydate':
            endpoint = `client/schedule/${id}?layout=bydate`
            break
        case path.join('_') === 'schedule_bytour':
            endpoint = `client/schedule/${id}?layout=bytour`
            break
    }

    if(endpoint) {
        try {

            const resp = await axios.get(`${ENDPOINT}${endpoint}`)
            return resp.data ? useRaw ? resp.data : resp.data.map(stage => {
                return stage.groups ? stage : {
                    _id: stage._id,
                    name: stage.title,
                    type: stage.type,
                    standings: stage.nodes ? {nodes: stage.nodes, thirdNode: stage.thirdNode, splitted: stage.splitted, isStartPoint: stage.isStartPoint} : stage.standings,
                    teams: stage.teams,
                    active: stage.active
                }
            }) : []

            // return resp.data[0] && (resp.data[0].standings || resp.data[0].groups) ? resp.data.map(stage => ({
            //     _id: stage._id,
            //     name: stage.title,
            //     type: stage.type,
            //     standings: stage.nodes ? {nodes: stage.nodes, thirdNode: stage.thirdNode, splitted: stage.splitted} : stage.standings,
            //     teams: stage.teams
            // })) : resp.data || []
        } catch(e) {
            console.log('Fetch err', e)
        }
    } else if(mock) {
        return mock
    }
}
